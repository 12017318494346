import React, { useContext } from "react";
import { ContextApp } from "../utils/Context";
import { LuPanelLeftOpen } from "react-icons/lu";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { RiSendPlane2Fill } from "react-icons/ri";
import Chat from "./Chat";
import "./ChatContainer.css";  // Import the CSS file

function ChatContainer() {
  const {
    showSlide,
    selectedFile,
    chatValue,
    setChatValue,
    handleSend,
    handleKeyPress,
  } = useContext(ContextApp);

  return (
    <div className={showSlide ? "chat-container-open" : "chat-container-closed"}>
      {/* chat section */}
      <Chat />
      {/* chat input section */}
      <div className="chat-input-container">
        <span className="chat-input-wrapper">
          <input
            type="text"
            placeholder="Ask a question"
            className="chat-input"
            value={chatValue}
            onChange={(e) => setChatValue(e.target.value)}
            onKeyUp={handleKeyPress}
            hidden={!selectedFile}
          />
          <RiSendPlane2Fill
            hidden={!selectedFile}
            title="send message"
            className={
              chatValue.length <= 0
                ? "send-icon-disabled"
                : "send-icon-active"
            }
            onClick={handleSend}
          />
        </span>
      </div>
    </div>
  );
}

export default ChatContainer;
