import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AppContext from "./utils/Context";
import { ReactAuthWrapper } from '@carrier/reactauthwrapper';
import appConfig from './config';

const callback = (permissions) => {
  console.log('permissions->',permissions);
}

ReactDOM.render(
  <ReactAuthWrapper appConfig={appConfig.api} getPermissions={callback} >
    <AppContext>
      <App />
    </AppContext>
  </ReactAuthWrapper>
  ,
  document.getElementById('root')
);


