import React, { useContext, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { LuPanelLeftClose } from "react-icons/lu";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FiMessageSquare, FiTrash } from "react-icons/fi";
import { SlOptions } from "react-icons/sl";
import { ContextApp } from "../utils/Context";
import { API } from "./MyApi";

function LeftNav() {
	const { reset_memory, reset_newchat, getBaseFilename, showSlide, handleHistClick, handleDeleteClick, chatmemorylimit,
		userHistory, setChatmemorylimit, selectedFile } = useContext(ContextApp);

	return (
		<div
			className={
				!showSlide
					? "h-screen bg-gray-900 w-[350px] border-r border-gray-500 hidden lg:flex items-center justify-between p-2 text-white flex-col translate-x-0"
					: "hidden"
			}
		>
			<div style={{ marginTop: "6rem" }} className="flex items-start justify-between w-full">
				<span
					style={selectedFile !== null ? { backgroundColor: 'rgb(25 118 210)' } : {}}
					className="border border-gray-600  rounded w-[90%] py-2 text-xs flex gap-1 items-center justify-center cursor-pointer"
					onClick={() => reset_newchat()}
				>
					<AiOutlinePlus fontSize={18} />
					New Chat
				</span>
			</div>
			<div className="h-[100%] w-full p-2 flex items-start justify-start flex-col overflow-hidden overflow-y-auto text-sm scroll my-2">
				{userHistory && userHistory.length > 0 ? (
					userHistory.slice().reverse().map((historyItem, index) => {
						const fileWithTimestamp = Object.keys(historyItem)[0];
						const fileWithTimestamp_ext = fileWithTimestamp+".parquet"
						const fileInfo = historyItem[fileWithTimestamp];
						const shortfilename = getBaseFilename(fileInfo.filename);
						console.log("fileWithTimestamp_ext",fileWithTimestamp_ext,"selectedFile",selectedFile)
						return (
							<span
								style={fileWithTimestamp_ext === selectedFile ? { backgroundColor: 'rgb(51 64 93)' } : {}}
								key={fileWithTimestamp}
								className="rounded w-full py-3 px-2 text-xs my-2 flex gap-1 items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap"
								value={fileWithTimestamp}
								onClick={() => handleHistClick(fileWithTimestamp + ".parquet", shortfilename)}
							>
								<span className="flex gap-2 items-center justify-center text-base">
									<FiMessageSquare />
									<span
										className="text-[14px] block overflow-hidden text-ellipsis whitespace-nowrap"
										style={{ maxWidth: '180px' }}
									>
										{shortfilename}
									</span>
								</span>

								{/* Delete Icon */}
								<span
									className="cursor-pointer hover:text-red-600 transition-all duration-300"
									onClick={(e) => {
										e.stopPropagation(); // Prevent click from propagating to parent element
										handleDeleteClick(fileWithTimestamp);
									}}
								>
									<BiSolidTrashAlt style={{ fontSize: "1.5rem" }} />
								</span>
							</span>
						);
					})
				) : (
					<span className="w-full py-3 px-2 text-xs my-2 text-center"></span>
				)}

			</div>
			{/* bottom section  */}
			<div className="w-full border-t border-gray-600 flex flex-col gap-2 items-center justify-center p-2">
				<span className="rounded w-full py-2 px-2 text-xs flex gap-1 items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300">
					<span className="flex gap-1 items-center justify-center text-sm">
						LLM Memory Limit
					</span>
					<span className="rounded-md px-1.5 py-0.5 text-xs font-medium uppercase">
						<input
							type="number"
							min="0"
							placeholder="Limit"
							value={chatmemorylimit}
							className="text-center rounded-md px-2 py-1 w-16 bg-gray-700 text-white"
							onChange={(e) => setChatmemorylimit(Number(e.target.value))} // Set chat memory limit
						/>
					</span>
				</span>
			</div>
			<div className="w-full border-t border-gray-600 flex flex-col gap-2 items-center justify-center p-2">
				<span onClick={() => reset_memory()} className="rounded w-full py-2 px-2 text-xs flex gap-1 items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300">
					<span className="flex gap-1 items-center justify-center text-sm">
						Clear Memory
					</span>
					<span className="rounded-md px-1.5 py-0.5 text-xs font-medium uppercase">
						<BiSolidTrashAlt style={{ fontSize: "1.5rem" }} />
					</span>
				</span>
			</div>
		</div>
	);
}

export default LeftNav;
