import React, { useState, useContext, useEffect, useRef } from "react";
import { ContextApp } from "../utils/Context";
import './Chat.css';
import axios from "axios";
import CustomizedTables from "../utils/CustomizedTables";
import { Button, Modal, Box, Typography, Select, MenuItem, IconButton } from '@mui/material'; // Importing Material-UI Button
import robotIcon from '../pics/robot5.png';
import { FcBusinessman } from "react-icons/fc"; // Businessman icon for bot
import { Avatar } from '@mui/material'; // Importing Material-UI Avatar
import { API } from "./MyApi";
import * as XLSX from 'xlsx';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Icon for file
import { Snackbar, Alert } from '@mui/material';
import Plot from 'react-plotly.js';
import MarkdownFormat from '../utils/MarkdownFormat';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '18px',
  p: 4,
};

function Chat() {
  const { setLoaderOpen, sheetName, setSheetName, message, email, setMessage, reset_newchat, msgEnd, selectedFile, setSelectedFile, user, get_userhistory, getBaseFilename } = useContext(ContextApp);
  const fileInputRef = useRef(null);
  const [availableSheets, setAvailableSheets] = useState([]);
  const [isExcelFile, setIsExcelFile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fileobj, setFileobj] = useState("");
  const [snackopen, setSnackopen] = useState(false);

  useEffect(() => {
    const chatContainer = document.querySelector('.chat-container');
    // //Limit the chat code
    // if (message.length > 10) {
    //   const preservedMessages = message.slice(0, 2);
    //   const latestMessages = message.slice(-10);
    //   const updatedMessages = [...preservedMessages, ...latestMessages.slice(preservedMessages.length)];
    //   setMessage(updatedMessages);
    // }
    if (chatContainer) {
      chatContainer.scrollTop += 3000;
    }
  }, [message]);

  const handleCloseModal = () => setOpenModal(false);

  const handleFileChange = async (event) => {
    console.log("im also here")
    if (!event.target.files || !event.target.files[0]) {
      reset_newchat();
    }
    else {
      setFileobj(event.target.files[0])
      const file = event.target.files[0]
      console.log("im in selected file state", event.target.files[0])

      if (file.name.includes('.xls')) {
        setSnackopen(true)
        setTimeout(async () => {
          setIsExcelFile(true);
          try {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data, { type: 'array' });

            const sheets = workbook.SheetNames;
            if (sheets.length === 1) {
              setOpenModal(false);
              handleUpload(file);
            }
            else {
              setOpenModal(true);
            }
            setAvailableSheets(sheets);
            setSheetName(sheets[0]);
          } catch (error) {
            console.error('Error reading the Excel file:', error);
            alert('Failed to read the Excel file. Please try again.');
          }
        }, 0);
      }
      else {
        handleUpload(file);
      }
    }
  };

  const handleSubmit = (event) => {
    if (sheetName) {
      console.log(`File: ${fileobj.name}, Sheet Name: ${sheetName}`);
      handleUpload(fileobj);
    } else {
      alert('Please select a valid sheet name.');
      reset_newchat()
    }
    setOpenModal(false); // Close modal after submission
  };

  const handleSheetChange = (event) => {
    setSheetName(event.target.value);
  };

  const handleUpload = async (file) => {
    if (true) {
      setLoaderOpen(true);
      try {
        const myfilename = file.name;
        const presignedResponse = await API.postData("/pre_signed_url_for_file", { "file_name_": myfilename });
        const { fields, url, actual_filename } = presignedResponse;

        console.log('Pre-signed URL fields:', fields);
        console.log('Pre-signed URL:', url);
        console.log('Actual filename:', actual_filename);

        const formData = new FormData();
        for (const [key, value] of Object.entries(fields)) {
          formData.append(key, value);
        }
        formData.append('file', file);

        for (let pair of formData.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }

        const uploadResponse = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log('Upload response:', uploadResponse);
        if (uploadResponse.status === 204 || uploadResponse.status === 200) {
          console.log('File uploaded successfully to S3');
        }

        const resp = await API.postData("/upload_file_to_s3", { "useremail": email, "uploaded_file_name": actual_filename, "sheet_name": sheetName });
        console.log("File uploaded resp:", resp);
        get_userhistory();
        setMessage([
          {
            text: "Hello **" + user + "**, Let's start using **Ainsights Bot**",
            isBot: true,
            uploadbutton: true,
          },
          { text: "Currently working on - **File :: " + getBaseFilename(resp.filekey) + "**" + resp.sheetname, isBot: true }
        ]);
        setSelectedFile(resp.filekey);

      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file.");
      }
      finally {
        setLoaderOpen(false);
      }
    }
  };

  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackopen(false);
  };

  // Function to trigger file input click
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileDialogClose = () => {
    if (!fileInputRef.current.files.length) {
      console.log("File dialog was closed without selecting a file.");
    }
  };

  return (
    <div className="chat-container w-full w-[89%] h-[80%] flex items-center justify-center overflow-y-hidden overflow-y-auto px-1 py-1 scroll">
      <div className="chat-wrapper w-full lg:w-4/5 flex flex-col h-full items-start justify-start">
        <div style={{ marginTop: "5rem" }}></div>
        {user && message?.map((msg, i) => (
          <span
            key={i}
            className={
              msg.isBot
                ? "bot-message flex items-start justify-center gap-2 lg:gap-5 my-2 p-3 rounded-md"
                : "user-message flex items-start justify-end gap-2 lg:gap-5 my-2 p-3 rounded-md"
            }
          >
            {msg.isBot ? (
              <img src={robotIcon} style={{ width: '3rem', height: '3rem' }} />
            ) : (
              <Avatar
                sx={{
                  bgcolor: '#d24a19',
                  width: "3rem",
                  height: '3rem',
                }}
              >
                {user ? user[0].toUpperCase() : 'U'}
              </Avatar>
            )}
            {msg.table && (
              <div style={{ marginRight: "7rem" }} className="table-container">
                <CustomizedTables columns={msg.table.columns} data={msg.table.data} />
                <br />
                <MarkdownFormat text={msg.tableanswer} />
              </div>
            )}

            {msg.chart && (
              <div className="chart-container mt-2">
                <Plot data={msg.chart.data} layout={{ ...msg.chart.layout, width: 950, height: 500 }} />
                {/* <img src={`data:image/png;base64,${msg.chart}`} alt="Chart" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                <br />
                <br />
                <MarkdownFormat text={msg.chartanswer} />
                <br />
                <MarkdownFormat text={msg.chartintr_steps} />
              </div>
            )}

            {/* {msg.intr_steps && <MarkdownFormat text={msg.intr_steps} />} */}
            {msg.text &&
              <div>
                <MarkdownFormat text={msg.text} />
                <br />
                <MarkdownFormat text={msg.textintr_steps} />
              </div>}

            {msg.uploadbutton && (
              <div className="upload-container">
                <input
                  type="file"
                  accept=".csv, .parquet, .xls, .xlsx"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  onClick={(e) => e.target.value = null} // Reset file input to allow reselecting the same file
                  onBlur={handleFileDialogClose} // Detect when the file dialog closes without selection
                />
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleButtonClick}
                  disabled={selectedFile}
                >
                  Upload Data File
                </Button>
              </div>
            )}
          </span>
        ))}
        <div ref={msgEnd} />
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
            <InsertDriveFileIcon sx={{ mr: 1, color: '#1976d2' }} />
            Please Select Sheet Name
          </Typography>

          <Select
            value={sheetName}
            onChange={handleSheetChange}
            fullWidth
            sx={{ mt: 2 }}
          >
            {availableSheets.map((sheet, index) => (
              <MenuItem key={index} value={sheet}>
                {sheet}
              </MenuItem>
            ))}
          </Select>

          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              mt: 3,
              bgcolor: '#1976d2',
              '&:hover': { bgcolor: '#1565c0' }
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Snackbar
        style={{ marginTop: "14rem" }}
        open={snackopen}
        autoHideDuration={2000}
        onClose={handlesnackClose}
        message="Just a moment! We're Extracting the sheet names from Excel file..."
        anchorOrigin={{
          vertical: 'top',  // Adjust vertical positioning
          horizontal: 'center',  // Adjust horizontal positioning
        }}
      />
    </div>
  );
}

export default Chat;
