// const tenant = 'unob2c.onmicrosoft.com';

export const appConfig = {
    api: {

      apiUrl: process.env.REACT_CARRIER_APPS_URL,

      uiUrl: process.env.REACT_APP_URL,

      postLogoutRedirectUri: "http://localhost:3000",
    },
  };
  export default appConfig;
