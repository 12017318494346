import Home from "./Page/Home";
import ResponsiveAppBar from './components/Navbar';
import './App.css'

function App() {
  return (
    <div className="overflow-hidden">
      <ResponsiveAppBar/>
      <Home />
    </div>
  );
}

export default App;
