import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MarkdownFormat = ({ text }) => {
  return (
    <ReactMarkdown
      children={text}
      remarkPlugins={[remarkGfm, remarkBreaks]}
      rehypePlugins={[rehypeRaw]}
      className="markdown-output"
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');

          if (inline) {
            return (
              <code
                className={`inline-code ${className || ''}`}  // Apply CSS class for inline code
                {...props}
              >
                {children}
              </code>
            );
          }

          return !inline && match ? (
            <SyntaxHighlighter
              language={match[1]}
              style={atomDark}  // Set the preferred theme
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '\n')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        p({ node, ...props }) {
          return <p {...props} style={{ marginBottom: '0.2em', marginTop: '0.2em' }} />;
        },
        ol({ node, ...props }) {
          return <ol {...props} style={{ paddingLeft: '1.5em' }} />;
        },
        li({ node, ...props }) {
          return <li {...props} style={{ marginBottom: '0.8em', marginTop: '0.8em' }} />;
        }
      }}
    />
  );
};

export default MarkdownFormat;
