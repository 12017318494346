import React, { createContext, useEffect, useRef, useState } from "react";
import { sendMsgToAI } from "./OpenAi";
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { API } from '../components/MyApi';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './Context.css'
export const ContextApp = createContext();


const AppContext = ({ children }) => {
  const [showSlide, setShowSlide] = useState(false);
  const [loaderopen, setLoaderOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userHistory, setUserHistory] = React.useState([]);
  const [Mobile, setMobile] = useState(false);
  const [chatmemorylimit, setChatmemorylimit] = useState(0);
  const [sheetName, setSheetName] = useState('');
  const [user, setUser] = useState("User");
  const [chatValue, setChatValue] = useState("");
  const [message, setMessage] = useState([]);
  const [email, setEmail] = useState("");
  const msgEnd = useRef(null);

  useEffect(() => {
    get_username();
  }, []);

  useEffect(() => {
    if (email !== ""){
      get_userhistory();
    }
  }, [email]);
  const getBaseFilename = (filename) => {
    const parts = filename.split('_');
    return parts.slice(0, -6).join('_');
  };

  const get_username = async () => {
    setLoaderOpen(true);
    const param = {};
    try {
      setMessage([
        {
          text: "Hello **User**, Let's start using **Ainsights Bot**",
          isBot: true,
          uploadbutton: true,
        }])
      const userdata = await API.getData("/get_username", param);
      console.log("userdata", userdata);

      if (userdata && userdata.username) {
        setUser(userdata.username);
        setEmail(userdata.email)
        console.log(userdata.username, "is set");

        setMessage([
          {
            text: "Hello **" + userdata.username + "**, Let's start using **Ainsights Bot**",
            isBot: true,
            uploadbutton: true,
          },
        ]);
      }
    } catch (error) {
      console.error("Failed to get userdata", error);
    }
    finally {
      setLoaderOpen(false);
    }
  };

  const handleSend = async () => {
    setLoaderOpen(true);
    const newinput = chatValue;
    setChatValue("");

    setMessage((prevMessages) => [...prevMessages, { text: newinput, isBot: false }]);

    const param = { query: newinput, filekey: selectedFile, useremail: email, chat_memory_limit: chatmemorylimit };
    try {
      const response = await API.postData("/query_llm", param);
      console.log("query_llm", typeof response, response);

      if (typeof response === 'string') {
        try {
          response = JSON.parse(response);
          console.log("Parsed query_llm response:", response); // Log the parsed response
        } catch (e) {
          console.error("Failed to parse response as JSON:", e);
        }
      }

      if (response && response.table) {
        console.log("table response", response.table);
        const tableData = {
          columns: response.table.columns,
          data: response.table.data,
        };
        setMessage((prevMessages) => [...prevMessages, { table: tableData, tableanswer:response.answer, isBot: true }]);
      }
      else if (response && response.chart) {
        setMessage((prevMessages) => [...prevMessages, { chart: response.chart, chartanswer:response.answer, chartintr_steps:response.intr_steps , isBot: true }]);
      }
      else if (response && response.answer) {
        setMessage((prevMessages) => [...prevMessages, { text: response.answer, textintr_steps:response.intr_steps, isBot: true }]);
      }
      // else if (response && response.intr_steps) {
      //   setMessage((prevMessages) => [...prevMessages, { intr_steps: response.intr_steps, isBot: true }]);
      // }
    } catch (error) {
      console.error("Failed to get query_llm", error);
      setMessage((prevMessages) => [
        ...prevMessages,
        { text: "Failed to query. Refine the prompt and try again", isBot: true },
      ]);
    } finally {
      setLoaderOpen(false);
    }
  };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const get_userhistory = async () => {
    const param = {"useremail":email};
    try {
      const resp = await API.postData("/get_user_history", param);
      console.log("user history data", resp);

      if (resp && resp.data) {
        setUserHistory(resp.data);
        console.log("userHistory:", userHistory);
      }
    } catch (error) {
      console.error("Failed to get userdata", error);
    }
    finally {
    }
  };

  const handleHistClick = async (filekey, filename) => {
    console.log("user filekey", filekey);
    setSelectedFile(filekey)
    setMessage([
      {
        text: "Hello **" + user + "**, Let's start using **Ainsights Bot**",
        isBot: true,
        uploadbutton: true,
      },
      { text: "Currently working on: **" + filename + "**", isBot: true }
    ]);
    setLoaderOpen(true);
    const param = { file_key: filekey };
    const userqueries = await API.postData("/get_filequery_history", param);
    console.log("user file query data", userqueries);
    setMessage((prevMessages) => [...prevMessages, ...userqueries]);
    setLoaderOpen(false);
  }

  const reset_memory = async () => {
    setChatValue("");
    setSelectedFile(null)
    setMessage([
      {
        text: "Hello **" + user + "**, Let's start using **Ainsights Bot**",
        isBot: true,
        uploadbutton: true,
      },
    ]);
    setLoaderOpen(true)
    const param = {};
    await API.postData("/reset_memory", param);
    console.log("user history reset done");
    setUserHistory([])
    setLoaderOpen(false)
  };

  const reset_newchat = async () => {
    console.log("Reset Chat")
    setChatValue("");
    setSelectedFile(null)
    setSheetName("");
    setMessage([
      {
        text: "Hello **" + user + "**, Let's start using **Ainsights Bot**",
        isBot: true,
        uploadbutton: true,
      },
    ]);
  };

  const handleDeleteClick = async (filekey) => {
    setLoaderOpen(true)
    console.log("user filekey", filekey);
    const updatedUserHistory = userHistory.filter(record => !record[filekey]);
    setUserHistory(updatedUserHistory);
    console.log("selectedFile", selectedFile, "filekey", filekey)
    try {
      if (selectedFile && (selectedFile.includes(filekey))) {
        reset_newchat()
      }

      const param = { "file_Key": filekey };
      const resp = await API.postData("/remove_filedata", param);

      console.log("user handleDeleteClick data", selectedFile, filekey);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file.");
    }
    finally{
      setLoaderOpen(false)
    }
  };

  return (
    <ContextApp.Provider
      value={{
        setMessage,
        showSlide,
        setShowSlide,
        Mobile,
        setMobile,
        chatValue,
        setChatValue,
        handleSend,
        message,
        msgEnd,
        email,
        handleKeyPress,
        handleHistClick,
        handleDeleteClick,
        selectedFile,
        setSelectedFile,
        get_userhistory,
        userHistory,
        reset_newchat,
        sheetName,
        setSheetName,
        reset_memory,
        chatmemorylimit,
        setChatmemorylimit,
        setLoaderOpen,
        getBaseFilename,
        user,
      }}
    >
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loaderopen}
      >
        <div class="dotsloader"></div>
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
      {children}

    </ContextApp.Provider>
  );
};

export default AppContext;
